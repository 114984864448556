import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import Container from "@material-ui/core/Container";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import bgBackground from "../images/v2/partner/banner-background.svg";
import partnerBannerImg from "../images/v2/partner/partner-with-us-banner-img.png";
import whoAreOurPartnersImg from "../images/v2/partner/who-are-our-partners.png";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Paper from "@material-ui/core/Paper";
import StepConnector from "@material-ui/core/StepConnector";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import BenefitsBG from "../images/v2/partner/BenefitsBG.svg";
import Benefits from "../images/v2/partner/Benefits.svg";
import Expectations from "../images/v2/partner/Expectations.svg";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DoneIcon from "@material-ui/icons/Done";
import PartnerWithUsImg from "../images/v2/partner/Partner_with_us.svg";
import FooterBackground from "../images/v2/partner/Footer_background.svg";
import WhiteButton from "../components/v2/WhightButton";
import requestDemoBGImg from "../images/v2/request-demo-bg.svg";
import Deploy from "../images/v2/partner/Deploy.svg";
import Engage from "../images/v2/partner/Engage.png";
import Train from "../images/v2/partner/Train.svg";
import loadable from "@loadable/component";
import PartnerWithUsDialog from "../components/v2/PartnerWithUsDialog";
import { useContactUsDialog } from "../components/v2/ContactUsDialog";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";
const { breakpoints } = createTheme({});
import { Link } from "gatsby";

const PartnerCarousel = loadable(() =>
  import("../components/v2/PartnerCarousel")
);

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      position: "relative",
      overflow: "hidden",
      background: `url(${bgBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  btn: {
    // minWidth: 307,
    // minHeight: 52,
    // fontWeight: 600,
    // fontSize: 20,
  },
  benefitsRoot: {
    background: `url(${BenefitsBG}) no-repeat top center`,
    backgroundSize: "100%",
  },
  listPrimary: {
    fontSize: 16,
    color: "#2e3f4f",
  },
  FooterRoot: {
    background: `url(${FooterBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginTop: theme.spacing(8),
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  partnersLogoImg: {
    // maxWidth: 140,
    width: "100%",
    height: "auto",
    padding: "0 1rem",
    // filter: "grayscale(100%)",
  },
  cimg: {
    filter: "grayscale(100%)",
    "&:hover": {
      filter: "none",
    },
  },
  partnerBannerImgClass: {
    width: "440px",
    height: "420px",
  },
}));
// const partnersLogo = [
// 	[
// 		{
// 			name: "Cuscapi",
// 			logo: partnerLogo1,
// 		},
// 		{
// 			name: "weeloy",
// 			logo: partnerLogo2,
// 		},
// 		{
// 			name: "Vouch",
// 			logo: partnerLogo3,
// 		},
// 		{
// 			name: "Abytech",
// 			logo: partnerLogo4,
// 		},
// 	],
// 	[
// 		{
// 			name: "Dymenz",
// 			logo: partnerLogo5,
// 		},
// 		{
// 			name: "Urus Siskomp",
// 			logo: partnerLogo6,
// 		},
// 		{
// 			name: "PC",
// 			logo: partnerLogo7,
// 		},
// 	],
// ];
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 32,
  },
  line: {
    height: 3,
    border: 0,
    borderRadius: 1,
    borderBottom: "1px dashed #0291ae",
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: "#fff",
    width: 70,
    height: 70,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4592ae",
    fontSize: 26,
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 600,
    [breakpoints.down("sm")]: {
      width: 57,
      height: 57,
      fontSize: 20,
    },
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  // eslint-disable-next-line react/prop-types
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {/* eslint-disable-next-line react/prop-types */}
      {props.icon}
    </div>
  );
}

const PartnerWithUs = () => {
  const width = useWidth();
  const classes = useStyles();
  const steps = ["", "", ""];
  const [openPartner, setOpenPartner] = useState(false);

  const partnerStepList = [
    {
      alt: "CRM Business partners icon",
      img: Engage,
      title: "Engage",
      description: (
        <>
          Engage with your prospects or existing customers and offer them easy
          to use sales CRM to grow their sales multifold.
        </>
      ),
    },
    {
      alt: "Wrench icon to indicate assistance in deployement of Sales CRM",
      img: Deploy,
      title: "Deploy",
      description:
        "Assist in implementation, data migration, customisation, and other services as per the customer’s requirements.",
    },
    {
      alt: "Document icon to indicate educating about sales CRM software",
      img: Train,
      title: "Educate",
      description: (
        <>
          Educate your customers on how to make the{" "}
          <a href="https://blog.peppercloud.com/complete-guide-to-crm-software/">
            {" "}
            best use of CRM software
          </a>
          , through training, consulting, and support.
        </>
      ),
    },
  ];

  const benefits = [
    {
      alt: "CRM partnership benefits",
      img: Benefits,
      title: "Your Benefits",
      list: [
        "Sell the best CRM software at the right price.",
        "Earn high commission on each product sale.",
        "Retain the full amount earned through the implementation and training of the CRM software.",
        "Get a free Pepper Cloud CRM demo account.",
        "List your brand on our marketing and publicity materials.",
        "Gain total control over your market and customers. We don’t involve directly with the customers of our business partners.",
        <>
          Priority information on new{" "}
          <a href="https://blog.peppercloud.com/tag/product-update/">
            {" "}
            product features{" "}
          </a>
          and regular enhancements.
        </>,
        <>
          Full training and support on new features and{" "}
          <a href="https://peppercloud.com/integration">
            {" "}
            CRM system integrations
          </a>
          .
        </>,
        <>
          <a href="https://peppercloud.com/contact">Contact us</a> to get
          support from our sales and business development team in closing your
          sales.
        </>,
      ],
    },
    {
      alt: "Technical skill list",
      img: Expectations,
      title: "Our Expectations",
      list: [
        "Good understanding of local business, CRM concepts, business operations, and sales strategy.",
        "Focused on providing convenient and easy-to-use technology products to your existing customers to maximise their value.",
        "Experienced in working with cloud-based technology or similar products.",
        "Keen on independently handling lead generation and closure in your market.",
        "Dedicated sales team to build and serve the market.",
        "Technical skills and competency to handle customer queries, third party integrations, and product customisations.",
      ],
    },
  ];

  return (
    <React.Fragment>
      <SEO
        canonical="/become-crm-partner/"
        description="Learn how to partner with Pepper Cloud and grow with the best sales CRM for SMEs. Start earning revenue as a Pepper Cloud CRM partner or CRM reseller for every sale you make."
        keywords="CRM Partner,
				CRM reseller,
        CRM Partner,
        Business Partner,"
        pathname="/become-crm-partner/"
        title="Become a CRM Partners/CRM Reseller | Pepper Cloud"
      />
      <div className={classes.root}>
        <Box py={{ sm: 0, xs: 0 }[width] || 4}>
          {/* <Container> */}
          <Grid alignItems="center" container justify="center">
            <Grid item md={1} sm={1} xs={12} />
            <Grid item md={5} sm={5} xs={12}>
              <Box
                px={{ xs: 2 }[width]}
                textAlign={{ xs: "center" }[width] || "left"}
              >
                <HeaderTypography
                  component="h2"
                  mb={4}
                  mt={{ sm: 6, xs: 5 }[width] || 7}
                >
                  Partner with us and grow!
                </HeaderTypography>
                <ParagraphTypography component="h2" mb={6}>
                  Join our trusted community of business partners to enhance
                  your solution portfolio with specialised CRM applications.
                </ParagraphTypography>
                <Button
                  className={classes.btn}
                  color="secondary"
                  onClick={() => setOpenPartner(true)}
                  size="large"
                  variant="contained"
                >
                  Learn more
                </Button>
                <Box mt={{ xl: 14, md: 14, sm: 3, xs: 3 }} />
              </Box>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Box
                pt={{ xs: 6 }[width]}
                textAlign="right"
                pl={{ xs: 6 }[width]}
              >
                <Box
                  alt="A hand-held puzzle piece pic to indicate the need of business partnerships."
                  component="img"
                  className={classes.partnerBannerImgClass}
                  src={partnerBannerImg}
                />
              </Box>
            </Grid>
          </Grid>
          {/* </Container> */}
        </Box>
        <Divider light />
      </div>

      <Box mb={5} mt={5} />

      <Box component={Container} mt={{ md: 12 }}>
        <Box display="flex" justifyContent="center">
          <Box maxWidth={1030} pb={3} textAlign="center">
            <HeaderTypography component="h3" m={0}>
              Who are our partners?
            </HeaderTypography>
            <ParagraphTypography fontSize={18} pb={4}>
              Our partners are consultants, integrators, and resellers who offer
              value-added services to their customers.
            </ParagraphTypography>

            <Box mb={5} textAlign="center">
              <Box
                alt="Business partners joining hands together"
                component="img"
                height="auto"
                src={whoAreOurPartnersImg}
                width="100%"
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mb={{ xl: 5, md: 5 }} mt={{ xl: 5, md: 5 }}>
        <Divider light />
      </Box>

      <Container>
        <Box>
          <Box display="flex" justifyContent="center">
            <Box maxWidth={1030} pb={3} textAlign="center">
              <HeaderTypography component="h1" mb={0}>
                Partner with Singapore’s #1 Sales CRM{" "}
              </HeaderTypography>
              <ParagraphTypography fontSize={18} pb={4}>
                Assist the clients to experience various benefits of CRM
                implementation. Become our consulting/reseller partners to grow
                together and, earn together.
              </ParagraphTypography>
            </Box>
          </Box>
          <Stepper
            activeStep={2}
            alternativeLabel
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Grid container spacing={3}>
            {partnerStepList.map((each) => (
              <Grid item key={each.title} md={4} sm={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  mt={3}
                  textAlign="center"
                >
                  <Box maxWidth={350}>
                    <Box
                      alt={each.alt}
                      component="img"
                      height={62}
                      src={each.img}
                    />

                    <HeaderTypography
                      component="h3"
                      fontSize={22}
                      fontWeight={600}
                    >
                      {each.title}
                    </HeaderTypography>
                    <ParagraphTypography fontSize={16}>
                      {each.description}
                    </ParagraphTypography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box mb={6} mt={6} textAlign="center">
            <Button
              className={classes.btn}
              color="secondary"
              component={Link}
              size="large"
              to="/contact"
              variant="contained"
            >
              Get Started
            </Button>
          </Box>
        </Box>
        <br />
        <br />
      </Container>

      <Box className={classes.benefitsRoot} pt={5}>
        <HeaderTypography
          color="common.white"
          fontSize={40}
          mb={2}
          mt={0}
          textAlign="center"
        >
          We are loved by our partners
        </HeaderTypography>
        <ParagraphTypography
          color="common.white"
          fontSize={22}
          m={0}
          textAlign="center"
        >
          Because our expectations are lesser than your benefits.
        </ParagraphTypography>
        <Box pb={8} />
        <Container>
          <Grid container spacing={6}>
            {benefits.map((eachBenefit, index) => (
              <Grid item key={eachBenefit.title} md={6} sm={12}>
                <Box
                  borderRadius={10}
                  component={Paper}
                  height="100%"
                  p={3}
                  textAlign="center"
                >
                  {index === 0 ? (
                    <Box
                      alt={eachBenefit.alt}
                      component="img"
                      mt={4}
                      src={eachBenefit.img}
                      width={100}
                    />
                  ) : (
                    <Box
                      alt={eachBenefit.alt}
                      component="img"
                      mb={2}
                      mt={4}
                      src={eachBenefit.img}
                      width={64}
                    />
                  )}

                  <HeaderTypography fontSize={30}>
                    {eachBenefit.title}
                  </HeaderTypography>
                  <List>
                    {eachBenefit.list.map((each, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <ListItem key={index}>
                        <Box
                          bgcolor="#009f53"
                          borderRadius="50%"
                          color="#FFFFFF !important"
                          component={ListItemIcon}
                          fontSize={16}
                          height={34}
                          minWidth="34px !important"
                          mr={2}
                          p={1}
                          width={34}
                        >
                          <DoneIcon fontSize="inherit" />
                        </Box>
                        <ListItemText
                          classes={{ primary: classes.listPrimary }}
                          primary={each}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box my={{ xl: 5, md: 5 }}>
        <Divider light />
      </Box>
      <Grid alignItems="center" container justify="center">
        <Grid item md={6} sm={12}>
          <Box
            display="flex"
            justifyContent={{ sm: "center", xs: "center" }[width] || "flex-end"}
          >
            <Box maxWidth={620} mt={4} p={1}>
              <HeaderTypography
                // alignItems="center"
                component="h2"
                // fontSize="h4.fontSize"
                // fontWeight={600}
                // lineHeight={1.12}
                mb={3}
                textAlign={{ sm: "center", xs: "center" }[width]}
              >
                Become a CRM expert
              </HeaderTypography>

              <ParagraphTypography component="h3" mt={0}>
                Be a part of the fastest-growing{" "}
                <a href="https://peppercloud.com/">Singapore’s #1 sales CRM</a>{" "}
                and become an expert in the CRM tool that serves your customers
                efficiently and helps them to nurture their customer
                relationships.
              </ParagraphTypography>
              <Box
                mb={4}
                mt={4}
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                <Button
                  className={classes.btn}
                  color="secondary"
                  onClick={() => setOpenPartner(true)}
                  size="large"
                  variant="contained"
                >
                  Learn more
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box textAlign="right">
            <img
              alt="A system of Google drive, Mailchimp, Google sheets, Whatsapp, Outlook, and the best CRM tool, Pepper Cloud"
              className={classes.img}
              src={PartnerWithUsImg}
            />
          </Box>
        </Grid>
      </Grid>
      <Box my={5}>
        <Divider light />
      </Box>
      <Container>
        <Box display="flex" justifyContent="center">
          <Box maxWidth={1030} mb={6} pb={3} textAlign="center" width="100%">
            <HeaderTypography component="h2" mb={0}>
              Our strategic partners
            </HeaderTypography>
            <ParagraphTypography fontSize={18} pb={4}>
              Pepper Cloud partners are located across the South East Asia
              region. And we welcome enquiries from you, to represent Pepper
              Cloud in your region.
            </ParagraphTypography>
            <PartnerCarousel className={classes.cimg} />
            {/*<HomeSlider dotPosition="bottom">*/}
            {/*	{partnersLogo.map((eachList, index) => (*/}
            {/*		// eslint-disable-next-line react/no-array-index-key*/}
            {/*		<Grid alignItems="center" container justify="center" key={index} spacing={4}>*/}
            {/*			{eachList.map((each) => (*/}
            {/*				<Grid item key={each.name} md={2} sm={6} xs={12}>*/}
            {/*					<img alt={each.name} className={classes.partnersLogoImg} src={each.logo} />*/}
            {/*				</Grid>*/}
            {/*			))}*/}
            {/*		</Grid>*/}
            {/*	))}*/}
            {/*</HomeSlider>*/}
          </Box>
        </Box>
      </Container>
      <Box className={classes.requestDemoRoot} py={12}>
        <Container>
          <HeaderTypography
            color="common.white"
            component="h2"
            fontSize={40}
            mb={2}
            mt={0}
            textAlign="center"
          >
            Partner with Pepper Cloud
          </HeaderTypography>
          <ParagraphTypography
            color="common.white"
            component="h3"
            fontSize={22}
            m={0}
            textAlign="center"
          >
            Join the growing community of our business partners today, and
            contribute to the growth of your customers.
          </ParagraphTypography>
          <Box display="flex" justifyContent="center" mt={6}>
            <WhiteButton
              color="primary"
              component={Link}
              size="large"
              to="/contact"
              variant="contained"
            >
              Contact us
            </WhiteButton>
          </Box>
        </Container>
      </Box>
      <PartnerWithUsDialog onClose={setOpenPartner} open={openPartner} />
    </React.Fragment>
  );
};

export default PartnerWithUs;
