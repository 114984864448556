import React from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import PartnerImg from "../../images/v2/partner.png";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ParagraphTypography from "./Typography/ParagraphTypography";
import Hidden from "@material-ui/core/Hidden";
import PropTypes from "prop-types";

const useStyle = makeStyles(() => ({
	root: {
		overflow: "unset",
	},
	closeButton: {
		position: "absolute",
		right: -24,
		top: -24,
		background: "#2e3f4f",
		padding: 8,
		"& svg": {
			fontSize: "40px",
			color: "#fff",
		},
		"&:hover": {
			backgroundColor: "#485e73",
		},
	},
	typoContainer: {
		backgroundImage: "linear-gradient(to bottom, #ffffff, #e7f2fe)",
	},
}));

function PartnerWithUsDialog({ open, onClose }) {
	const classes = useStyle();

	const handleClose = () => {
		onClose(false);
	};

	return (
		<Dialog
			aria-labelledby="customized-dialog-title"
			// className={classes.root}
			classes={{ paper: classes.root }}
			fullWidth
			keepMounted
			maxWidth="md"
			onClose={handleClose}
			open={open}
			scroll="body"
		>
			<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
				<CloseIcon />
			</IconButton>
			<Grid container spacing={2}>
				<Grid item md={4}>
					<Hidden smDown>
						<Box className={classes.typoContainer} height="99%" p={2} pt={15}>
							<Box alt="Contact Us" component="img" height={"auto"} maxWidth={"100%"} src={PartnerImg} />
							<ParagraphTypography color="#2e3f4f" fontSize={18} fontWeight={600}>
								We are eager to partner with you. Please send us your details and let’s talk.
							</ParagraphTypography>
							<Box bgcolor="#0291ae" borderRadius={5} height={5} width={59} />
						</Box>
					</Hidden>
				</Grid>
				<Grid item md={8} sm={12}>
					<Box height={892} p={2}>
						<Box
							border="none"
							component="iframe"
							height="100%"
							src="https://app.peppercloud.com/form/ab66ddfd-fe65-406f-9170-54fcea977468/embed"
							title="Partner with Us"
							width="99%"
						/>
					</Box>
				</Grid>
			</Grid>
		</Dialog>
	);
}

PartnerWithUsDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default PartnerWithUsDialog;
